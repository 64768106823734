<template>
  <div class="select-product-input">
    <el-input :value="fastSearchValue"
      :placeholder="$t('components_select_product_input_178')" suffix-icon="el-icon-search" @input="changeSearchValue"
      @change="prepareToSearch"  name="select-product-input"></el-input>
    <img class="page-header-container__center--keyboard" src="~@/assets/images/common/keyboard.png" alt=""
      @click="showSearchKeyboard" />
  </div>
</template>

<script>
import i18n from "../locales/index"

import { mapState, mapMutations } from "vuex"
import selectProductInput from "@/components/order-panel/selectProductInput.js"

export default {
  name: "SelectProductInput",
  components: {},
  mixins: [selectProductInput],
  data() {
    return {
      keyfn: undefined,
    }
  },
  mounted() {
  },
  beforeDestroy() {
  },
  computed: {},
  methods: {
    // 切换快速搜索
    showSearchKeyboard() {
      this.SET_SHOW_FAST_SEARCH(!this.showFastSearch)
    },
  },
}
</script>

<style lang="scss" scoped>
.select-product-input {
  display: flex;
  align-items: center;
  width: 480px;
  height: 44px;

  >div {
    width: 100% !important;
    border: 1px solid rgba(143, 143, 143, 0.6);
    border-radius: 200px;
    overflow: hidden;

    ::v-deep .el-input__inner {
      background: rgba(253, 220, 220, 0.2);
      border: none;
    }

    .el-input__suffix {
      right: 15px;
      font-weight: 500;
      font-size: 15px;
    }
  }

  &--keyboard {
    width: 74px;
    height: 74px;
    margin-left: 20px;
  }
}
</style>
