import i18n from "../../locales/index"
// 切换到后台
export function openUrlOnBrowser() {
  if (window.require) {
    const { ipcRenderer } = window.require("electron")
    if (ipcRenderer) {
      ipcRenderer.send("toAdmin")
    }
  }
}
export async function getWinVer() {
  if (window.require) {
    const { ipcRenderer } = window.require("electron")
    try {
      const version = await ipcRenderer.invoke('get-app-version');
      console.log(`应用版本: ${version}`);
      return version
      // 这里可以更新 UI 来显示版本号
    } catch (error) {
      console.error('获取版本号时出错:', error);
    }
  }
}
// 打印机列表
export function windowsPrinterList() {
  if (window.require) {
    const { ipcRenderer } = window.require("electron")
    if (ipcRenderer) {
      ipcRenderer.send("getPrinterList")
      return new Promise((resovle) => {
        ipcRenderer.once("getPrinterList", (event, data) => {
          resovle(data)
        })
      })
    }
    return false
  }
  return false
}

// 返回已打开所以窗口的数量
export function allWindowsLength() {
  if (window.require) {
    const { ipcRenderer } = window.require("electron")
    if (ipcRenderer) {
      ipcRenderer.send("getAllWindowsLength")
      return new Promise((resovle) => {
        ipcRenderer.once("getAllWindowsLength", (event, data) => {
          resovle(data)
        })
      })
    }
    return false
  }
  return false
} 

// 客显
export function ledDisplay(LedPort, optype, price ) {
  if (window.require) {
    const { ipcRenderer } = window.require("electron")
    if (ipcRenderer) {
      console.log("这里调用了ledDisplay原生方法", LedPort, optype, price)
      ipcRenderer.send("LedDisplay", LedPort, optype, price)
    }
  }
}
// 打印小票
export function windowsPrint(content, printname) {
  if (window.require) {
    const { ipcRenderer } = window.require("electron")
    if (ipcRenderer) {
      console.log("这里调用了windowsPrint原生方法", printname, content)
      ipcRenderer.send("windowsPrint", printname, content)
    }
  }
}

// 条码称打印
export function windowsLabelPrint(content, name) {
  // const basecontent = new Buffer(JSON.stringify(content)).toString('base64');
  // console.log('windowsLabelPrint调用', name, basecontent);
  if (window.require) {
    const { ipcRenderer } = window.require("electron")
    if (ipcRenderer) {
      ipcRenderer.send("windowsLabelPrint", name, content)
      console.log("windowsLabelPrint调用", name, content)
    }
  }
}

// 开钱箱
export function OpenCashBox(printname) {
  if (window.require) {
    const { ipcRenderer } = window.require("electron")
    if (ipcRenderer) {
      console.log("windows 弹钱箱 printname", printname)
      ipcRenderer.send("openCashBox", printname)
    }
  }
}

// 开启副屏
export function secondScreenShow() {
  console.log("windows 开启副屏方法调用")
  if (window.require) {
    const { ipcRenderer } = window.require("electron")
    if (ipcRenderer) {
      ipcRenderer.send("secondScreenShow")
    }
  }
}

// 关闭副屏
export function secondScreenClose() {
  console.log("windows 关闭副屏方法调用")
  if (window.require) {
    const { ipcRenderer } = window.require("electron")
    if (ipcRenderer) {
      ipcRenderer.send("secondScreenClose")
    }
  }
}

// 副屏传数据
export function secondScreenDataTransfer(params) {
  if (window.require) {
    const { ipcRenderer } = window.require("electron")
    if (ipcRenderer) {
      ipcRenderer.send("secondScreenDataTransfer", params)
    }
  }
}

/**
 *最小化应用
 */
export function minimizeWindow() {
  if (window.require) {
    const { ipcRenderer } = window.require("electron")
    if (ipcRenderer) {
      console.log("调用最小化应用方法")
      ipcRenderer.send("minimizeWindow")
    }
  }
}
